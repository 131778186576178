<div [formGroup]="formGroup">
  <div class="form-group">
    <h3>{{ 'APPLICATION.PAGE.PAYMENT.TITLE' | translate }}</h3>
    <p class="mb-3">{{ 'APPLICATION.PAGE.PAYMENT.TEXT' | translate }}</p>
    <p>
      <strong>{{ 'APPLICATION.PAGE.PAYMENT.BANK_INFORMATION' | translate }}</strong>
    </p>
    <div class="row mb-2">
      <div class="form-group col-12 col-lg-6">
        <div class="form-floating" *ngIf="inputMode === 'iban'">
          <input
            nvInput
            type="text"
            id="iban"
            data-cy="iban"
            name="iban"
            formControlName="iban"
            maxlength="64"
            autocomplete="off"
            [placeholder]="'LABEL.IBAN' | translate"
          />
          <label for="iban">{{ 'LABEL.IBAN' | translate }}</label>
          <nv-error
            id="iban-error-required"
            data-cy="iban-error-required"
            *ngIf="formGroup.controls.iban.errors?.required && formGroup.controls.iban.touched"
            >{{ 'ERROR.REQUIRED' | translate }}</nv-error
          >
          <nv-error
            id="iban-error-pattern"
            data-cy="iban-error-pattern"
            *ngIf="
              formGroup.controls.iban.errors?.invalidIban &&
              formGroup.controls.iban.touched &&
              !formGroup.controls.iban.errors?.required
            "
            >{{ 'APPLICATION.PAGE.PAYMENT.ERROR.IBAN_PATTERN' | translate }}</nv-error
          >
        </div>
        <div class="form-floating" *ngIf="inputMode === 'accountNumber'">
          <input
            nvInput
            type="text"
            id="iban-determined"
            name="iban"
            formControlName="iban"
            autocomplete="off"
            placeholder="{{ 'LABEL.IBAN' | translate }} - {{ 'PLACEHOLDER.DETERMINED_AUTOMATICALLY' | translate }}"
            readonly
          />
          <label for="iban"
            >{{ 'LABEL.IBAN' | translate }}
            <span>- {{ 'PLACEHOLDER.DETERMINED_AUTOMATICALLY' | translate }}</span></label
          >
        </div>
      </div>
    </div>
    <div class="mb-3">
      <nv-link (click)="toggleInputMode($event, 'accountNumber')" *ngIf="inputMode === 'iban'">{{
        'APPLICATION.PAGE.PAYMENT.INPUT_MODE.BANK_ACCOUNT' | translate
      }}</nv-link>

      <nv-link (click)="toggleInputMode($event, 'iban')" *ngIf="inputMode === 'accountNumber'">{{
        'APPLICATION.PAGE.PAYMENT.INPUT_MODE.IBAN' | translate
      }}</nv-link>
    </div>
    <div class="row mb-3" *ngIf="inputMode === 'accountNumber'">
      <div class="form-group col-12 col-lg-6">
        <div class="form-floating">
          <input
            nvInput
            id="account-number"
            data-cy="account-number"
            name="account-number"
            formControlName="accountNumber"
            maxlength="10"
            [placeholder]="'LABEL.ACCOUNT_NUMBER' | translate"
          />
          <label for="account-number">{{ 'LABEL.ACCOUNT_NUMBER' | translate }}</label>
          <nv-error
            id="account-number-error-required"
            data-cy="account-number-error-required"
            *ngIf="formGroup.controls.accountNumber.errors?.required && formGroup.controls.accountNumber.touched"
            >{{ 'ERROR.REQUIRED' | translate }}</nv-error
          >
          <nv-error
            id="account-number-error-pattern"
            data-cy="account-number-error-pattern"
            *ngIf="
              formGroup.controls.accountNumber.errors?.pattern &&
              formGroup.controls.accountNumber.touched &&
              !formGroup.controls.accountNumber.errors?.required
            "
            >{{ 'APPLICATION.PAGE.PAYMENT.ERROR.BANK_ACCOUNT_PATTERN' | translate }}</nv-error
          >
        </div>
      </div>
      <div class="form-group col-12 col-lg-6">
        <div class="form-floating">
          <input
            nvInput
            id="bank-code"
            data-cy="bank-code"
            name="bank-code"
            formControlName="bankCode"
            maxlength="8"
            placeholder="{{ 'LABEL.BANK_CODE' | translate }}"
          />
          <label for="bank-code">{{ 'LABEL.BANK_CODE' | translate }}</label>
          <nv-error
            id="bank-code-error-required"
            data-cy="bank-code-error-required"
            *ngIf="formGroup.controls.bankCode.errors?.required && formGroup.controls.bankCode.touched"
            >{{ 'ERROR.REQUIRED' | translate }}</nv-error
          >
          <nv-error
            id="bank-code-error-pattern"
            data-cy="bank-code-error-pattern"
            *ngIf="
              formGroup.controls.bankCode.errors?.pattern &&
              formGroup.controls.bankCode.touched &&
              !formGroup.controls.bankCode.errors?.required
            "
            >{{ 'APPLICATION.PAGE.PAYMENT.ERROR.BANK_CODE_PATTERN' | translate }}</nv-error
          >
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-12 col-lg-6">
        <div class="form-floating">
          <input
            nvInput
            type="text"
            id="bank-name"
            data-cy="bank-name"
            name="bank-name"
            formControlName="bankName"
            placeholder="{{ 'PLACEHOLDER.DETERMINED_AUTOMATICALLY' | translate }}"
          />
          <label for="bank-name"
            >{{ 'LABEL.BANKNAME' | translate }}
            <span> - {{ 'PLACEHOLDER.DETERMINED_AUTOMATICALLY' | translate }}</span></label
          >
        </div>
      </div>
      <div class="form-group col-12 col-lg-6">
        <div class="form-floating">
          <input
            nvInput
            type="text"
            id="bic"
            data-cy="bic"
            name="bic"
            formControlName="bic"
            placeholder="{{ 'PLACEHOLDER.DETERMINED_AUTOMATICALLY' | translate }}"
          />
          <label for="bic"
            >{{ 'LABEL.BIC' | translate }}
            <span>- {{ 'PLACEHOLDER.DETERMINED_AUTOMATICALLY' | translate }}</span></label
          >
        </div>
      </div>
    </div>
    <p>
      <strong>{{ 'APPLICATION.PAGE.PAYMENT.BANK_HOLDER' | translate }}</strong>
    </p>
    <div class="row mb-3" *ngIf="showName">
        <div class="form-group col-12 col-lg-6">
          <div class="form-floating">
            <input
              nvInput
              type="text"
              id="first-name"
              data-cy="iban-first-name"
              name="firstname"
              formControlName="firstname"
              [placeholder]="'LABEL.FIRST_NAME' | translate"
            />
            <label for="first-name">{{ 'LABEL.FIRST_NAME' | translate }}</label>
          </div>
        </div>
        <div class="form-group col-12 col-lg-6">
          <div class="form-floating">
            <input
              nvInput
              type="text"
              id="last-name"
              data-cy="iban-last-name"
              name="lastname"
              formControlName="lastname"
              [placeholder]="'LABEL.LAST_NAME' | translate"
            />
            <label for="last-name">{{ 'LABEL.LAST_NAME' | translate }}</label>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="form-group d-flex col-12">
        <nv-checkbox formControlName="mandate" data-cy="mandate-checkbox"
        ><p>{{ 'APPLICATION.PAGE.PAYMENT.CHECKBOX' | translate }}</p></nv-checkbox
        >
        <div class="form-group">
          <nv-info-text-container>
            <nv-info-modal *ngIf="'APPLICATION.PAGE.PAYMENT.CHECKBOX_CONTENT.INFO_MODAL.INFO' | translate as info">
              <ng-container *ngFor="let block of info">
                <p [innerHTML]="block.DISCLAIMER"></p>
              </ng-container>
            </nv-info-modal>
          </nv-info-text-container>
        </div>
      </div>
    </div>
  </div>
</div>
