<form [formGroup]="formGroup" *ngIf="formGroup.controls.birthDate as birthDateControl" nv-form>
  <input
    nvInput
    type="text"
    id="birthdate"
    class="form-control"
    autocomplete="off"
    data-cy="birthdate"
    formControlName="birthDate"
    [placeholder]="'PLACEHOLDER.DATE' | translate"
    mask="00.00.0000"
    [dropSpecialCharacters]="false"
    (input)="onInput($event)"
  />
  <nv-error
    id="birthdate-error-required"
    data-cy="birthdate-error-required"
    *ngIf="birthDateControl.errors?.required && birthDateControl.touched"
  >
    {{ 'ERROR.REQUIRED' | translate }}
  </nv-error>
  <nv-error
    id="birthdate-error-invalid-date-format"
    data-cy="birthdate-error-invalid-date-format"
    *ngIf="birthDateControl.errors?.invalidDateFormat && birthDateControl.touched"
  >
    {{ 'ERROR.DATE_FORMAT_INVALID' | translate }}
  </nv-error>
  <!-- Birthdate Datepicker -->
  <ng-container *ngIf="this.validateInsurableAge">
    <nv-error
      id="birthdate-error-invalid-min-age"
      data-cy="birthdate-error-invalid-min-age"
      *ngIf="birthDateControl.errors?.invalidMinAge && !birthDateControl.errors.required && birthDateControl.touched"
    >
      {{ 'ERROR.MIN_AGE' | translate }}
    </nv-error>
    <nv-error
      id="birthdate-error-invalid-max-age"
      data-cy="birthdate-error-invalid-max-age"
      *ngIf="birthDateControl.errors?.invalidMaxAge && !birthDateControl.errors.required && birthDateControl.touched"
    >
      {{ 'ERROR.MAX_AGE' | translate }}
    </nv-error>
    <nv-error
      id="birthdate-error-is-future-date"
      data-cy="birthdate-error-is-future-date"
      *ngIf="birthDateControl.errors?.isFutureDate && birthDateControl.touched"
      >{{ 'ERROR.DATE_IS_FUTURE' | translate }}
    </nv-error>
  </ng-container>

  <nv-error
    id="birthdate-error-invalid-insurable-date"
    data-cy="birthdate-error-invalid-insurable-date"
    *ngIf="
      (birthDateControl.errors?.invalidMinAge || birthDateControl.errors?.invalidMaxAge) &&
      !birthDateControl.errors.required &&
      birthDateControl.touched &&
      !this.validateInsurableAge
    "
  >
    {{ 'ERROR.INVALID_INSURABLE_BIRTHDATE' | translate }}
  </nv-error>
</form>
