<form nv-form [formGroup]="personalDataForm" (submit)="onSubmit($event)">
  <div class="row mb-3">
    <div class="col-12">
      <nv-card class="border-primary-1">
        <nv-application-personal-details
          [value]="initialPersonalDetails"
          (formReady)="formInitialized('personalDetails', $event)"
          [config]="personalDetailsConfig$ | async"
        ></nv-application-personal-details>
        <h3>{{ 'APPLICATION.PAGE.PERSONAL_DATA.ADDRESS.TITLE' | translate }}</h3>
        <nv-application-address-data
          [value]="initialAddress"
          [config]="addressDataConfig"
          (formReady)="formInitialized('address', $event)"
        ></nv-application-address-data>
      </nv-card>
    </div>
  </div>
  <nv-wizard-page-navigation
    [forwardLink]="nextLink"
    [forwardTitle]="'BTN.NEXT' | translate"
    [backLink]="previousLink"
    [backTitle]="'BTN.BACK' | translate"
  ></nv-wizard-page-navigation>
</form>
