import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TealiumConfig,
  TealiumUtagService,
  TrackingConfig,
  TrackingService,
  TrackingStore,
} from '@nbg-digital/shared/util-tracking';
import { of } from 'rxjs';

@Injectable()
export class OfferAgentTrackingService extends TrackingService {
  protected subBroker$ = of(null);
  protected processId$ = of(null);
  protected productCategory$ = of(null);
  protected productId$ = of(null);
  protected orderValue$ = of(null);
  protected orderDetails$ = of(null);
  protected orderId$ = of(null);
  protected userId$ = of(null);
  protected userAge$ = of(null);
  protected userGender$ = of(null);
  protected userOccupation$ = of(null);
  protected userGraduationLevel$ = of(null);
  protected userOfficeWork$ = of(null);

  constructor(
    router: Router,
    route: ActivatedRoute,
    breakpointObserver: BreakpointObserver,
    tealium: TealiumUtagService,
    @Inject('trackingConfig') config: TrackingConfig,
    @Inject('tealiumConfig') tealiumConfig: TealiumConfig,
    trackingStore: TrackingStore
  ) {
    super(router, route, breakpointObserver, tealium, config, tealiumConfig, trackingStore);
  }
}
