<div class="nv-card border-primary">
  <div class="nv-card-body">
    <h2>{{ 'PAGE.OFFER_EXPIRED.TITLE' | translate }}</h2>
    <p>
      {{ 'PAGE.OFFER_EXPIRED.INFO' | translate }}
    </p>
    <div class="row mb-3">
      <div class="col-12 text-center">
        <a class="btn btn-secondary px-3" href="tel:+498005318119">
          <i class="fas fa-phone fa-rotate-90 me-lg-1"></i>
          {{ 'CONSULTANT.PHONE_NUMBER' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
