<div [formGroup]="formGroup">
  <div *ngIf="!config.titleHidden">
    <h3>{{ 'APPLICATION.PAGE.PERSONAL_DATA.PERSONAL.TITLE' | translate }}</h3>
    <p>{{ 'APPLICATION.PAGE.PERSONAL_DATA.PERSONAL.SUB_TITLE' | translate }}</p>
  </div>
  <div class="row">
    <div class="form-group col-12 col-lg-4">
      <nv-dropdown
        id="salutation"
        data-cy="salutation-dropdown"
        formControlName="salutation"
        [placeholder]="'LABEL.SALUTATION' | translate"
        [ngClass]="{
          'offer-agent-dropdown': formGroup.controls['salutation'].touched && formGroup.controls['salutation'].invalid
        }"
      >
        <nv-dropdown-item
          *ngFor="let option of salutationOptions"
          [value]="option"
          attr.data-cy="{{ 'salutation-option-' + option }}"
          label="{{ 'OPTION.SALUTATION.' + option | translate }}"
        ></nv-dropdown-item>
      </nv-dropdown>
      <nv-error
        id="salutation-error-required"
        data-cy="salutation-error-required"
        *ngIf="formGroup.controls['salutation'].errors?.required && formGroup.controls['salutation'].touched"
        >{{ 'ERROR.REQUIRED' | translate }}</nv-error
      >
    </div>
    <div class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="first-name"
          data-cy="first-name"
          formControlName="firstname"
          autocomplete="given-name"
          [readonly]="!config.nameEditable"
          [placeholder]="'LABEL.FIRST_NAME' | translate"
        />
        <label for="first-name">{{ 'LABEL.FIRST_NAME' | translate }}</label>
        <nv-error
          id="first-name-error-required"
          data-cy="first-name-error-required"
          *ngIf="formGroup.controls['firstname'].errors?.required && formGroup.controls['firstname'].touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
      </div>
    </div>
    <div class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="last-name"
          data-cy="last-name"
          formControlName="lastname"
          autocomplete="family-name"
          [readonly]="!config.nameEditable"
          [placeholder]="'LABEL.LAST_NAME' | translate"
        />
        <label for="last-name">{{ 'LABEL.LAST_NAME' | translate }}</label>
        <nv-error
          id="lastname-error-required"
          data-cy="lastname-error-required"
          *ngIf="formGroup.controls['lastname'].errors?.required && formGroup.controls['lastname'].touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!config.phoneHidden" class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="phone"
          data-cy="phone"
          formControlName="phone"
          autocomplete="tel"
          [placeholder]="'LABEL.PHONE' | translate"
        />
        <label for="phone">{{ 'LABEL.PHONE' | translate }}</label>
        <nv-error
          id="phone-error-required"
          data-cy="phone-error-required"
          *ngIf="formGroup.controls['phone'].errors?.required && formGroup.controls['phone'].touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
        <nv-error
          id="phone-error-invalid-mobile-phone-number"
          data-cy="phone-error-invalid-mobile-phone-number"
          *ngIf="formGroup.controls['phone'].errors?.invalidNumber && formGroup.controls['phone'].touched"
          >{{ 'ERROR.INVALID_MOBILE_PHONE_NUMBER' | translate }}</nv-error
        >
        <nv-error
          id="phone-error-pattern"
          data-cy="phone-error-pattern"
          *ngIf="formGroup.controls['phone'].errors?.pattern && formGroup.controls['phone'].touched"
          >{{ 'APPLICATION.PAGE.PERSONAL_DATA.PERSONAL.ERROR.PHONE' | translate }}</nv-error
        >
      </div>
    </div>
    <div class="form-group col-12 col-lg-4 flex-lg-grow-1">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="city-of-birth"
          data-cy="city-of-birth"
          formControlName="cityOfBirth"
          autocomplete="do-not-autocomplete"
          [readonly]="!config.cityOfBirthEditable"
          [placeholder]="'LABEL.CITY_OF_BIRTH' | translate"
        />
        <label for="city-of-birth">{{ 'LABEL.CITY_OF_BIRTH' | translate }}</label>
        <nv-error
          id="phone-error-phone"
          data-cy="city-of-birth-error-required"
          *ngIf="formGroup.controls['cityOfBirth'].errors?.required && formGroup.controls['cityOfBirth'].touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
      </div>
    </div>
    <div *ngIf="!config.birthdateEditable" class="form-group col-12 col-lg-4 flex-lg-grow-1">
      <div class="form-floating">
        <input
          nvInput
          disabled
          type="text"
          data-cy="birthdate"
          id="birthdate"
          [value]="initialBirthdate | date: 'dd.MM.yyyy'"
          [placeholder]="'PLACEHOLDER.DATE' | translate"
        />
        <label for="birthdate">{{ 'LABEL.BIRTHDATE' | translate }}</label>
        <nv-error
          id="birthdate-error-required"
          data-cy="birthdate-error-required"
          *ngIf="formGroup.controls['birthDate'].errors?.required && formGroup.controls['birthDate'].touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
      </div>
    </div>
    <div *ngIf="config.birthdateEditable" class="form-group col-12 col-lg-4 flex-lg-grow-1">
      <nv-application-birthdate
        [value]="initialBirthdate"
        [validateInsurableAge]="config.validateInsurableAge"
        (formReady)="formInitialized('birthDate', $event)"
      >
      </nv-application-birthdate>
    </div>
    <div *ngIf="!config.nationalityHidden" class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <nv-auto-complete
          id="nationality"
          attr.data-cy="nationality"
          formControlName="nationality"
          [options]="nationalities | async"
          [readOnly]="!config.nationalityEditable"
          [placeholder]="'LABEL.NATIONALITY' | translate"
        >
        </nv-auto-complete>
        <nv-error
          id="nationality-error-required"
          data-cy="nationality-error-required"
          *ngIf="formGroup.get('nationality').errors?.required && formGroup.get('nationality').touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
        <nv-error
          id="nationality-error-pattern"
          data-cy="nationality-error-pattern"
          *ngIf="formGroup.get('nationality').errors?.minlength && formGroup.get('nationality').touched"
        >
          {{ 'ERROR.PATTERN_MIN' | translate }}</nv-error
        >
        <nv-error
          id="nationality-error-country"
          data-cy="nationality-error-country"
          *ngIf="formGroup.get('nationality').errors?.invalidOption && formGroup.get('nationality').touched"
          >{{ 'APPLICATION.PAGE.PERSONAL_DATA.PERSONAL.ERROR.COUNTRY' | translate }}</nv-error
        >
      </div>
    </div>
  </div>
</div>
