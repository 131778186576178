import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CountryResolver } from '@nbg-digital/application/feature';
import {
  AppConfigModule,
  AvbUrlDkr,
  CachingInterceptor,
  CmsUrl,
  HttpLoaderFactory,
  ProductService,
  ProductServiceFactory,
  ReentryUrlService,
  ReentryUrlServiceFactory,
} from '@nbg-digital/shared/util';
import { SharedUtilTrackingModule, TrackingService } from '@nbg-digital/shared/util-tracking';
import {
  NvConstructionPageModule,
  NvContainerModule,
  NvFooterModule,
  NvHeaderModule,
  NvLoadingSpinnerModule,
} from '@nbg-digital/ui-dpl-components';
import { NvRejectionModule, RejectionInterceptor } from '@nbg-digital/wizard/shell';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { APP_CONFIG } from './app-config';
import { AppRoutingModule, PRICING_PATH, REJECTION_PATH, RISK_ASSESSMENT_PATH } from './app-routing.module';
import { AppComponent } from './app.component';
import { RejectionPageComponent } from './rejection-page/rejection-page.component';
import { OFFER_AGENT_RLV_TRACKING_CONFIG } from './tracking/offer-agent-tracking-config';
import { OfferAgentTrackingService } from './tracking/offer-agent-tracking.service';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent, RejectionPageComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
      useDefaultLang: true,
    }),
    NvFooterModule,
    NvHeaderModule,
    NvRejectionModule,
    NvLoadingSpinnerModule,
    NvContainerModule,
    NvConstructionPageModule,
    SharedUtilTrackingModule.forRoot({
      service: {
        provide: TrackingService,
        useClass: OfferAgentTrackingService,
      },
      trackingConfig: OFFER_AGENT_RLV_TRACKING_CONFIG,
      tealiumConfig: {
        account: environment.tealiumAccount,
        profile: environment.tealiumProfile,
        environment: environment.tealiumEnvironment,
      },
      actionTracking: true,
    }),
    AppConfigModule.forRoot(APP_CONFIG),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RejectionInterceptor, multi: true },
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl,
    },
    {
      provide: 'userApiUrl',
      useValue: environment.userApiUrl,
    },
    {
      provide: ProductService,
      useFactory: ProductServiceFactory,
      deps: ['product'],
    },
    {
      provide: 'product',
      useValue: 'rlv',
    },
    {
      provide: ReentryUrlService,
      useFactory: ReentryUrlServiceFactory,
    },
    {
      provide: 'rlvUrl',
      useValue: environment.rlvUrl,
    },
    {
      provide: 'dkrUrl',
      useValue: environment.dkrUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: 'eweApiUrl',
      useValue: environment.eweApiUrl,
    },
    {
      provide: 'userApiUrl',
      useValue: environment.userApiUrl,
    },
    {
      provide: 'baseUrl',
      useValue: environment.baseUrl,
    },
    {
      provide: 'enableUserLogin',
      useValue: false,
    },
    {
      provide: 'channel',
      useValue: 'phone',
    },
    {
      provide: 'riskAssessmentPath',
      useValue: `${RISK_ASSESSMENT_PATH}`,
    },
    {
      provide: 'riskAssessmentCancelUrl',
      useValue: `${environment.baseUrl}/${RISK_ASSESSMENT_PATH}/vorvertragliche-anzeigepflicht`,
    },
    {
      provide: 'riskAssessmentResultUrl',
      useValue: `${environment.baseUrl}/${RISK_ASSESSMENT_PATH}/ergebnis`,
    },
    {
      provide: 'riskAssessmentErrorUrl',
      useValue: `${environment.baseUrl}/${RISK_ASSESSMENT_PATH}/fehler`,
    },
    {
      provide: 'rejectionUrl',
      useValue: `/${REJECTION_PATH}`,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
    {
      provide: 'mainBroker',
      useValue: 'EVOX',
    },
    {
      provide: 'enableConstructionPage',
      useValue: environment.enableConstructionPage,
    },
    {
      provide: 'skipMagnumGo',
      useValue: environment.skipMagnumGo,
    },
    {
      provide: 'e2e',
      useValue: environment.e2e,
    },
    {
      provide: 'pricingPath',
      useValue: PRICING_PATH,
    },
    {
      provide: 'magnumGoUrl',
      useValue: environment.magnumGoUrl,
    },
    {
      provide: 'magnumGoTenant',
      useValue: environment.magnumGoTenant,
    },
    {
      provide: 'magnumGoLanguage',
      useValue: environment.magnumGoLanguage,
    },
    {
      provide: 'cmsUrl',
      useValue: CmsUrl,
    },
    {
      provide: 'avbUrl',
      useValue: AvbUrlDkr,
    },
    {
      provide: 'defaultAcquisitionAgent',
      useValue: '658033333',
    },
    {
      provide: 'enableTealiumLogging',
      useValue: environment.enableTealiumLogging,
    },
    CountryResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
