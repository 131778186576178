<div [formGroup]="formGroup">
  <div class="row">
    <div class="form-group col-12">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="email"
          data-cy="email"
          formControlName="email"
          [readonly]="readOnly"
          [placeholder]="'LABEL.EMAIL' | translate"
        />
        <label for="email">{{ 'LABEL.EMAIL' | translate }}</label>
        <nv-error
          id="email-error-required"
          data-cy="email-error-required"
          *ngIf="formGroup.controls['email'].errors?.required && formGroup.controls['email'].touched"
          >{{ 'ERROR.EMAIL_REQUIRED' | translate }}</nv-error
        >
        <nv-error
          id="email-error-pattern"
          data-cy="email-error-pattern"
          *ngIf="formGroup.controls['email'].errors?.pattern && formGroup.controls['email'].touched"
          >{{ 'ERROR.EMAIL_PATTERN' | translate }}</nv-error
        >
        <nv-error
          id="email-error-duplicate"
          data-cy="email-error-duplicate"
          *ngIf="formGroup.controls['email'].errors?.alreadyExists && formGroup.controls['email'].touched"
          >{{ 'ERROR.EMAIL_ALREADY_EXISTS' | translate }}</nv-error
        >
      </div>
    </div>
  </div>
</div>
