<form nv-form [formGroup]="paymentForm" (submit)="onSubmit($event)">
  <div class="row mb-3">
    <div class="col-12">
      <nv-card class="border-primary-1">
          <nv-application-payment-data
            (formReady)="formInitialized('paymentData', $event)"
            [value]="initialPaymentData"
          >
          </nv-application-payment-data>
      </nv-card>
    </div>
  </div>
  <nv-wizard-page-navigation
    [forwardLink]="nextLink"
    [forwardTitle]="'BTN.NEXT' | translate"
    [backLink]="previousLink"
    [backTitle]="'BTN.BACK' | translate"
  ></nv-wizard-page-navigation>
</form>
