import { TrackingConfig } from '@nbg-digital/shared/util-tracking';

export const OFFER_AGENT_SESSION_DOMAIN = 'Evo-X';
export const OFFER_AGENT_PRODUCT_TARGET = '';

export const OFFER_AGENT_DKR_PRODUCT_NAME = 'Existenzsicherung|Krebsversicherung|Evo-X';
export const OFFER_AGENT_DKR_PRODUCT_GROUP = 'Existenzsicherung';
export const OFFER_AGENT_DKR_PRODUCT_LABEL = 'Krebsversicherung';
export const OFFER_AGENT_DKR_BASE_PAGE_NAME = 'EvoX/Produkt/Existenzsicherung/Krebsversicherung/OfferAgent';
export const OFFER_AGENT_DKR_BASE_PAGE_CATEGORIES = [
  'EvoX',
  '',
  'Produkte',
  'Existenzsicherung',
  'Krebsversicherung',
  'Formular',
];

export const OFFER_AGENT_RLV_PRODUCT_NAME = 'Lebensversicherung|Risikolebensversicherung|Evo-X';
export const OFFER_AGENT_RLV_PRODUCT_GROUP = 'Lebensversicherung';
export const OFFER_AGENT_RLV_PRODUCT_LABEL = 'Risikolebensversicherung';
export const OFFER_AGENT_RLV_BASE_PAGE_NAME = 'EvoX/Produkt/Lebensversicherung/Risikolebensversicherung/OfferAgent';
export const OFFER_AGENT_RLV_BASE_PAGE_CATEGORIES = [
  'EvoX',
  '',
  'Produkte',
  'Lebensversicherung',
  'Risikolebensversicherung',
  'Formular',
];

export const OFFER_AGENT_DKR_TRACKING_CONFIG: TrackingConfig = {
  basePageName: OFFER_AGENT_DKR_BASE_PAGE_NAME,
  basePageCategories: OFFER_AGENT_DKR_BASE_PAGE_CATEGORIES,
  productName: OFFER_AGENT_DKR_PRODUCT_NAME,
  productGroup: OFFER_AGENT_DKR_PRODUCT_GROUP,
  productTarget: OFFER_AGENT_PRODUCT_TARGET,
  productLabel: OFFER_AGENT_DKR_PRODUCT_LABEL,
  sessionDomain: OFFER_AGENT_SESSION_DOMAIN,
};

export const OFFER_AGENT_RLV_TRACKING_CONFIG: TrackingConfig = {
  basePageName: OFFER_AGENT_RLV_BASE_PAGE_NAME,
  basePageCategories: OFFER_AGENT_RLV_BASE_PAGE_CATEGORIES,
  productName: OFFER_AGENT_RLV_PRODUCT_NAME,
  productGroup: OFFER_AGENT_RLV_PRODUCT_GROUP,
  productTarget: OFFER_AGENT_PRODUCT_TARGET,
  productLabel: OFFER_AGENT_RLV_PRODUCT_LABEL,
  sessionDomain: OFFER_AGENT_SESSION_DOMAIN,
};
