<nv-container *ngIf="enableConstructionPage">
  <nv-construction-page></nv-construction-page>
</nv-container>
<ng-container *ngIf="!enableConstructionPage">
  <nv-header [logoClickable]="false"></nv-header>
  <section class="content" nv-action-area="content">
    <nv-container>
      <nv-loadingspinner #spinner [loading]="navigationLoader.loading$ | async"></nv-loadingspinner>
      <div [hidden]="(spinner.showSpinner$ | async) && (navigationLoader.loading$ | async)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </nv-container>
  </section>

  <nv-footer
    [imprintHref]="'APP.FOOTER.IMPRINT' | translate"
    [initialContactDetailsHref]="'APP.FOOTER.INITIAL_CONTACT_DETAILS' | translate"
    [privacyHref]="'APP.FOOTER.PRIVACY' | translate"
  ></nv-footer>
</ng-container>
