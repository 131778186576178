<router-outlet></router-outlet>
<nv-modal #modalPriceChange (closed)="modalClosed$.next(null)">
  <ng-template modalContent>
    <p>
      {{ 'PRICING_CHANGE_MODAL.TEXT' | translate }}
    </p>
    <div class="col-12 d-flex justify-content-end">
      <button nv-button type="submit" (click)="modalConfirmed$.next(true)">
        {{ 'BTN.YES' | translate }}
      </button>
    </div>
  </ng-template>
</nv-modal>
