import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RejectionPageComponent } from './rejection-page/rejection-page.component';
import { TrackingAwareRoute } from '@nbg-digital/shared/util-tracking';
import { NvServiceErrorPageComponent } from '@nbg-digital/ui-dpl-components';

export const PRICING_PATH = 'tarifierung';
export const RISK_ASSESSMENT_PATH = 'risikopruefung';
export const REJECTION_PATH = 'ablehnung';
export const SERVICE_ERROR_PATH = 'service-error';

const routes: TrackingAwareRoute[] = [
  {
    path: '',
    redirectTo: 'rlv',
    pathMatch: 'full',
  },
  {
    path: 'rlv',
    loadChildren: () => import('./offer-agent-rlv/offer-agent-rlv.module').then((m) => m.OfferAgentRlvModule),
  },
  {
    path: 'dkr',
    loadChildren: () => import('./offer-agent-dkr/offer-agent-dkr.module').then((m) => m.OfferAgentDkrModule),
  },
  {
    path: RISK_ASSESSMENT_PATH,
    loadChildren: () =>
      import('@nbg-digital/risk-assessment/feature-offer-agent').then((m) => m.RiskAssessmentFeatureOfferAgentModule),
  },
  {
    path: REJECTION_PATH,
    component: RejectionPageComponent,
    data: {
      tracking: {
        page: {
          type: 'Ablehnung',
          name: 'Ablehnung',
          category: 'Ablehnung',
        },
      },
    },
  },
  {
    path: SERVICE_ERROR_PATH,
    component: NvServiceErrorPageComponent,
    data: {
      tracking: {
        page: {
          type: 'Error',
          name: 'ServiceError',
          category: 'Error',
        },
      },
    },
  },
  {
    path: '**',
    redirectTo: 'rlv',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
